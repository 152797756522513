import React, { Component } from 'react'
import Scroll from 'react-scroll'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
// import Hero from 'src/components/Hero'
import ProductFan from 'src/components/ProductFan'
import FAQ from 'src/components/FAQ'
import Contact from 'src/components/Contact'
import ReviewChat from 'src/components/Review/ReviewChat'
import AllLinksList from 'src/components/AllLinksList'
// import CustomCarousel from 'src/components/CustomCarousel'
import ExampleReed from 'src/components/ExampleReed'
import HowToOrder from 'src/components/HowToOrder'

const Element = Scroll.Element

export default class Fan extends Component {
  state = {
    navbarHeight: null,
    fans: []
  }

  componentDidMount() {
    this.setState({
      navbarHeight: document.getElementById('navbar').offsetHeight
    })
    this.getFormattedProducts()
  }

  getFormattedProducts = () => {
    const _products = this.props.data.products.edges
    const formattedProducts = _products.map(product => {
      return {
        ...product.node.frontmatter,
        description: product.node.rawMarkdownBody
      }
    })
    this.setState({
      fans: formattedProducts
    })
  }

  render() {
    const { watsList } = this.props.data
    const { navbarHeight, fans } = this.state

    return (
      <Layout menuName="all-fan">
        {/* Edit event click menu */}
        <div style={{ paddingTop: navbarHeight }}>
          <SEO title="พวงหรีดพัดลม" />
          {/* <CustomCarousel isHomepage={true} /> */}
          {/* <Hero propName="all-fan" title="พัดลม" /> */}
          <HowToOrder isFanPage={true} />
          <ExampleReed isFanPage={true} />
          {/* <Trusted />
          <Review isFanPage={true} /> */}
          <Element name="productF">
            <ProductFan
              products={fans}
              categoryTitle={`พวงหรีดพัดลม`}
              isFanPage={true}
              priceRange="฿1,990 - 2,490"
            />
          </Element>
          <ReviewChat />
          <FAQ />
          <Element name="service-areas">
            <AllLinksList watsList={watsList} />
          </Element>
          <Contact />
        </div>
      </Layout>
    )
  }
}
