import React from 'react'
import { graphql } from 'gatsby'
import ReedFanPage from 'src/components/ReedFanPage'

export default function Template({ data }) {
  return <ReedFanPage data={data} />
}

export const pageQuery = graphql`
  query {
    watsList: allMarkdownRemark(filter: { frontmatter: { layout: { eq: "wat" } } }) {
      edges {
        node {
          frontmatter {
            name
            name2
            path
            group
            linkPosition
            landingPage {
              isShowOnLandingPage
              position
            }
          }
        }
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: { category: { eq: "F" }, fanBangkok: { isShowOnBangkok: { eq: true } } }
      }
    ) {
      edges {
        node {
          rawMarkdownBody
          frontmatter {
            best_seller
            category
            colorTone
            id
            image
            imageS3
            isShow
            price
            size
            slashPrice
            position
            descMobile
            fanBangkok {
              position
            }
          }
        }
      }
    }
  }
`
